import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { format, formatDuration } from 'date-fns';
import {
  AppStore,
  fetchEvent,
  EventDetails,
  useTitle,
  isEligible,
  EventCategory,
} from '../state';
import { Avatar } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import RichText from '../components/RichText';
import { RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

interface PathParams {
  id: string;
}

type Status =
  | 'booked'
  | 'waiting'
  | 'rejected'
  | 'full'
  | 'available'
  | 'unavailable'
  | 'saving';

export default function EventDetailsPage(
  props: RouteComponentProps<PathParams>
) {
  let levels = AppStore.useState((s) => s.main?.levels || []);
  let site = AppStore.useState((s) => s.main?.site);
  let [saving, setSaving] = React.useState<boolean>(false);
  let [error, setError] = React.useState<string>('');
  let [level, isMember] = AppStore.useState(
    (s) =>
      [s.main?.me.level || 1, s.main?.me.isMember || false] as [number, boolean]
  );

  let params = props.match.params;
  let id = parseInt(params.id, 10);
  // eslint-disable-next-line

  let event: EventDetails | undefined = AppStore.useState((s) => s.events[id], [
    id,
  ]);
  let categories = AppStore.useState((s) => s.main?.eventCategories || []);
  let category: EventCategory | undefined = categories.find(
    (c) => c.categoryId === event?.categoryId
  );

  React.useEffect(() => {
    fetchEvent(id);
  }, [id]);

  useTitle(category ? category.name : '', event && getBackLink(event));

  if (event == null) {
    return null;
  }

  let eventLevel = levels.find((l) => l.level === event?.level) || {
    level: 1,
    name: '',
  };
  let maxLevel = levels[levels.length - 1]?.level || 1;

  let status = saving ? 'saving' : getStatus(level, isMember, event);

  async function onClick() {
    setError('');
    if (status === 'available' || status === 'full') {
      setSaving(true);
      try {
        let resp = await axios.post(`/api/event/${id}/`);
        AppStore.update((s) => {
          if (s.main) {
            s.main.nextEvents = resp.data.nextEvents;
          }
        });

        fetchEvent(id);
      } catch (e) {
        if (e.message) {
          setError(e.message);
        }
      }
      setSaving(false);
    } else if (status === 'booked' || status === 'waiting') {
      setSaving(true);
      try {
        let resp = await axios.delete(`/api/event/${id}/`);
        AppStore.update((s) => {
          if (s.main) {
            s.main.nextEvents = resp.data.nextEvents;
          }
        });

        fetchEvent(id);
      } catch (e) {
        if (e.message) {
          setError(e.message);
        }
      }
      setSaving(false);
    }
  }

  let bgStyle = event.headerImage
    ? { backgroundImage: `url('${event.headerImage}')` }
    : {};

  return (
    <Wrap>
      <PullToRefresh onRefresh={() => fetchEvent(id)} pullingContent="">
        <>
          {error && (
            <MuiAlert severity="error" elevation={6} variant="filled">
              {error}
            </MuiAlert>
          )}

          <div
            className="backgroundContainer"
            style={bgStyle}
            onClick={() => {
              if (event && event.headerUrl) {
                let win = window.open(event.headerUrl, '_blank');
                if (win) win.focus();
              }
            }}
          />

          <div className="head">
            <p>{format(event.startsAt, 'EEEE, do LLLL yyyy, HH:mm')}</p>
            <h2>{event.name}</h2>
            <h3>
              at{' '}
              {event.locationUrl ? (
                <a href={event.locationUrl}>{event.locationName}</a>
              ) : (
                event.locationName
              )}
            </h3>
            {event.level != null && (
              <p className="gradeLevel">
                <span>{eventLevel.name}</span>
                {site?.showLevelStars && (
                  <Rating
                    name="read-only"
                    value={eventLevel.level}
                    max={maxLevel}
                    readOnly
                  />
                )}
              </p>
            )}
          </div>

          <div className="body">
            <p>
              Capacity:{' '}
              {event.capacity == null
                ? 'Unlimited'
                : `${event.capacity} (${event.left} left)`}
            </p>
            <p>
              Duration:{' '}
              {event.durationMinutes == null
                ? 'All day'
                : formatDuration({
                    hours: Math.floor(event.durationMinutes / 60),
                    minutes: event.durationMinutes % 60,
                  })}
            </p>
            <p>
              Meeting point:{' '}
              <a
                href={event.meetingPointUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {event.meetingPointLocationName || event.meetingPoint}
              </a>
            </p>
            <h4>Event Info</h4>
            <RichText html={event.eventInfo} />
            <h4>Requirements</h4>
            <RichText html={event.requirements} />
            <h4>{site?.membersTitlePlural || 'Members'}</h4>
            {event.members.map((m) => (
              <Attendee key={m.userId} {...m} />
            ))}
            <h4>{site?.attendeesTitlePlural || 'Attendees'}</h4>
            {event.bookers.map((m) => (
              <Attendee key={m.userId} {...m} />
            ))}
          </div>

          {status !== 'rejected' && status !== 'unavailable' && (
            <div className="foot" onClick={onClick}>
              {status === 'saving' && (
                <CircularProgress style={{ color: 'white' }} />
              )}
              {status === 'available' && 'Book'}
              {status === 'booked' && 'Cancel Booking'}
              {status === 'full' && 'Register on waiting list'}
              {status === 'waiting' && (
                <p>
                  You're on the waiting list
                  <br />
                  Click to cancel
                </p>
              )}
            </div>
          )}
        </>
      </PullToRefresh>
    </Wrap>
  );
}

function Attendee(props: { firstName: string; image?: string }) {
  return (
    <AttendeeWrap>
      <Avatar alt={`${props.firstName}'s image`} src={props.image} />
      {props.firstName}
    </AttendeeWrap>
  );
}

let AttendeeWrap = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-bottom: 10px;

  .MuiAvatar-root,
  img {
    width: 30px;
    height: 30px;
  }
  .MuiAvatar-root {
    margin-right: 10px;
  }
`;

function getBackLink(event: EventDetails): string {
  let month = event.startsAt.getMonth() + 1;
  let year = event.startsAt.getFullYear();
  return `/app/book/${event.categoryId}/${year}/${month}`;
}

function getStatus(
  level: number,
  isMember: boolean,
  event: EventDetails
): Status {
  if (event.bookingStatus != null) {
    if (event.bookingStatus === 0) return 'waiting';
    if (event.bookingStatus === 1) return 'booked';
    if (event.bookingStatus === 2) return 'rejected';
  }
  if (!event.available) return 'unavailable';
  if (isMember) return 'available';
  if (!isEligible(level, event)) return 'unavailable';
  if (event.bookingStatus == null) {
    return event.left > 0 || event.capacity == null ? 'available' : 'full';
  }
  return 'unavailable';
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);

  .head {
    text-align: center;
    padding: 20px 10px;
    font-size: 0.9em;

    p:first-child {
    }

    h2 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.3em;
    }

    h3 {
      font-size: 1em;
    }

    a {
      color: black;
    }

    a:hover {
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;
    }
  }

  .gradeLevel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 0px 0;
  }

  .gradeLevel > span:first-child {
    font-size: 1em;
    height: 1.3em;
    margin-right: 8px;
    text-transform: uppercase;
    font-variant: smallcaps;
  }

  .MuiRating-root {
    font-size: 1.3em;
    display: flex;
    justify-content: center;
  }

  .backgroundContainer {
    width: 100%;
    padding-top: 50%; /* trick to create a desired aspect-ratio */
    background: url('${require('../imgs/home-header.jpg').default}') 50% 100%;
    background-size: cover;
    position: relative;
  }

  .body {
    border-top: 2px solid #bbb;
    padding: 20px 20px;
    padding-bottom: 40px;

    font-size: 0.9em;

    h4 {
      font-size: 1.2em;
      margin: 15px 0 10px 0;
    }
  }

  .foot {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    bottom: 0;
    height: 55px;
    flex: 55px 0 0;
    width: 100%;
    background-color: var(--main-color);
    text-align: center;
    max-width: 600px;
  }
`;
